.shelf-container {
  position: relative;
  margin-top: 28px;

  .publish-shelf {
    position: absolute;
    right: 50px;
    top: 30px;
    font-weight: 500;
    cursor: pointer !important;

    &:hover {
      cursor: pointer;
      color: #000;
    }

    &.loading {
      right: 50px;
    }
  }
}

.user-menu {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  display: flex;
  min-width: 210px;
  list-style: none;
  background-clip: padding-box;
  text-indent: 0;

  li {
    display: block;
    padding: 15px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: $primary;
    white-space: nowrap;
    text-transform: uppercase;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;

    &:not(.divider):hover,
    &:not(.divider):focus {
      color: $light-alt;
      text-decoration: none;
      background-color: $dark-alt;
      outline: none;
    }

    &.divider {
      height: 1px;
      margin: 0;
      padding: 0;
      cursor: default;
      overflow: hidden;
      background-color: $light-alt;
      width: 100%;
    }
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;

  .modal-header-close {
    &:hover {
      color: darken($dark-grey, 10%);
      cursor: pointer;
    }
  }

  .modal-header-save {
    &:hover {
      color: darken($green, 10%);
      cursor: pointer;
    }
  }
}


.publish-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;


  .modal-header {
    width: 100%;
    height: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    z-index: 1;
  }

  .modal-header-inner {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1;
    padding: 0 15px;
  }

  .modal-header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-height: 48px;
  }

  .modal-content {
    display: flex;
    align-items: center;
    padding: 10px;
    flex-direction: column;
  }

  .form-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    &.unpublish {
      span {
        margin-bottom: 0;
      }
    }
  }

  .button-light {
    margin-right: 20px;
  }

  .public-shelf-image-preview {
    width: 400px;
    height: auto;
    margin-bottom: 30px;
  }

  .text {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $primary;
    padding-bottom: 10px;
  }

  .done {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      height: 35px;
      width: 50px;
      min-width: 150px;
      margin-bottom: 16px;
    }

    .button-underline {
      margin-bottom: 16px;
      color: $dark-alt;
    }
  }
}

.social-sharing-bar {
  display: flex;
  flex-direction: column;

  input:focus,
  &:focus,
  .button-copy:focus {
    border: 1px solid $dark-alt;
    outline: none;
  }

  .copy-box {
    // margin-right: 20px;
    // border-radius: 2px;
    // width: 334px;
    // max-width: 100%;

    display: flex;
    justify-content: center;
    height: 32px;

    .button-copy {
      height: 32px;
      cursor: pointer;
    }

    .form-control {
      height: 32px;
    }

    .input-addon {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 50%;
      }

      .form-control {
        border: 1px solid $dark-alt;
        border-right: 0;
        padding: 3px;
        background: $light-alt;
        color: $dark-alt;
        border-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
      }

      .button-copy {
        background-color: $dark-alt;
        color: $white;
        border: 1px solid $dark-alt;
        border-left: 0;
        border-radius: 2px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;



  .button-inner {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    display: flex;

    &:hover {
      cursor: pointer;
    }

    &.FACEBOOK {
      background: $facebook;
      margin-right: 10px;
    }

    &.TWITTER {
      background: $twitter;
    }

    svg {
      color: $white;
    }
  }


  .SocialMediaShareButton {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }



}
.tile-text {
  $noun-color: var(--noun-hex-color, $white);
  color: $noun-color;
  text-align: center;
  overflow: hidden;
  width: $tile-width;
  height: $tile-height;
  position: relative;
  margin-top: 50%;

  p {
    $hex-color: var(--hex-color, $white);
    $rgb-color: var(--rgb-color, $tile-color);

    background-image: linear-gradient(-15deg,
        rgba($rgb-color, 0.8) 60%,
        rgba($rgb-color, 0.8) 97%);
    padding-top: 4px;
    padding-bottom: 4px;
  }

  span:before {
    content: '';
    width: 50%;
    height: 100%;
    float: left;
    shape-margin: 2px;
    shape-outside: $tile-text-shape-outside-left; //polygon(32.9434932px 44.943038px, 0 44.943038px, 0 -7.10542736e-15px, 32.9434932px 44.943038px);
  }

  span:after {
    content: '';
    width: 50%;
    height: 100%;
    float: right;
    shape-outside: $tile-text-shape-outside-right; //polygon(26.5px 45px, 50.1022797px -2.84217094e-14px, 50.1022797px 45px, 26.5px 45px);
  }



  overflow: hidden;
  -webkit-line-clamp: 2;
  z-index: 100;
  text-overflow: ellipsis;
  font-family: $family-condensed;
  font-weight: bold;

  // to fit on 100px tile
  padding-bottom: 2px;
  padding-right: 0.5px;
  padding-left: 0.5px;
  line-height: 11px;
  font-size: 11px;
}
.confirm-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;

  .inner-content {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    background-image: linear-gradient(to right,
        #dd056a,
        #de0464,
        #df055d,
        #df0857,
        #df0d50);

    .confirm-signup-title {
      font-size: 30px;
      color: white;
      text-align: center;
      margin-bottom: 50px;
    }

    .confirm-signup-text {
      font-size: 20px;
      color: white;
      text-align: center;
    }
  }
}
.tile-image-container {

  position: absolute;

  &.full {
    position: absolute;
  }

  &.square {
    width: 55px;
    height: 55px;

    min-height: 55px;

    img {
      object-fit: contain;
    }
  }

  img {
    width: 100%;
    height: 100%;

  }
}

.tile-inner {
  .tile-image-inset,
  .tile-image-overlay {
    width: $tile-width;
    height: $tile-height;
    background-size: cover;
    background-position: center;
    position: absolute;
  }

  .tile-image-overlay {
    z-index: 101;
    position: absolute;
    opacity: 0;
    transition: .2s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .2s ease-out;
    &.active {
      opacity: 1;
    }
  }
}
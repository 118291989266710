.deck-boards {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow: hidden; // switch to auto to have scroll on cards
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  margin: 0;
  position: relative;
  padding: 30px 0;
  //padding-bottom: 0;
  padding-bottom: 10px; //for scrollbar

  &.top-6 {
    padding-top: 10px;
  }

  &.no {
    overflow-x: none;
  }

  .ps__rail-x {
    display: flex;
  }
}

.simplebar-content {
  display: flex;
}

.deck {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.button {
  height: 54px;
  width: 235px;
  min-width: 223px;
  background-color: $primary;
  box-shadow: 0 12px 24px rgba(34, 51, 49, 0.13);
  border-radius: 30px;
  outline: 0;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.875rem;
  line-height: 16px;
  text-align: center;
  margin: 32px 0;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: rgba($primary, 0.9);
    color: #fff;
  }
}

@mixin button-light {
  background-color: #fff;
  color: $black;

  &:hover {
    background-color: #fff;
    color: $primary;
  }
}

@media screen and (max-height: 700px) {
  .btn {
    margin: 1.5rem 0;
  }
}

.icon-button {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d8d8d8;
  cursor: pointer;
}

.button-underline {
  text-decoration: underline;

  &:hover {
    box-shadow: none;
    color: darken($dark-grey, 10%);
    cursor: pointer;
  }
}

.button-light {
  @include button-light();
  box-shadow: none;

  border: 1px solid $lighter-grey;
  color: $dark-grey;

  &:hover {
    box-shadow: none;
    color: darken($dark-grey, 10%);
    border: 1px solid $dark-grey;
  }
}
$PLACEHOLDER_HEIGHT: 48px;
$PREVIEW_PLACEHOLDER_HEIGHT: $PLACEHOLDER_HEIGHT;
$CARD_HEIGHT: 300px;
$IMAGE_HEIGHT: 90px;
$CARD_CORNERS: 16px;
$PREVIEW_CARD_HEIGHT: 100px;

.board-container {
  width: 220px;
  min-width: 220px;
  max-height: 430px;
  transition: all 1s ease-out;
  // opacity: .5;
  transform: translateX(100vw) translateY(100vh) rotate(15deg);
  margin-right: 15px;
  margin-left: 5px;
  cursor: move;

  &.last {
    width: 240px;
    min-width: 240px;
    padding-right: 18px;
  }

  &.loaded {
    opacity: 1;
    transform: translateX(0) translateY(0) rotate(0);

    &.not-dragging {
      align-self: flex-start;
      height: auto;
      min-height: 100%;
      display: flex;
    }
  }

  &.loaded .content.loaded {
    opacity: 1;
  }

  .content {
    opacity: 0;
    transition: all 2s ease-out;
  }

  &:not(.not-dragging) {
    .priority-position-container.has-content {
      max-height: 100%;

      .position {
        max-height: 100%;
      }
    }
  }
}

.board {
  width: 100%;
  height: 100%; // remove this to be smaller
  min-height: 430px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin-bottom: 30px;

  &.top-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    min-height: 0;
    margin-bottom: 0;

    .items {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .top-6-title {
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      text-align: right;
      font-weight: 700;
      margin-right: 10px;
      margin-top: 10px;
      display: flex;
      color: $st-dark;
    }

    .priority-position-container {
      margin-bottom: 0;
      min-height: 96px;
      height: 96px;
      width: 134px;
      max-width: 134px;
      border-radius: 16px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 10px;
      margin-left: 0;
      cursor: pointer;


      &.filled {
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        box-shadow: 6px 2px 16px rgba(136, 165, 191, 0.48), -6px -2px 16px rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        background-color: #E3EDF7;
        overflow: visible;
      }

      .position {
        margin-left: 5px
      }

      .position.filled {
        background: none;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $CARD_CORNERS;
        width: 100%;
        height: 100%;
        flex: 1;
        margin-left: 0;

        &.drop-zone {}

        &.drop-over {
          background: darken(#E3EDF7, 10%);
        }
      }
    }
  }
}

.priority-position-container {
  position: relative;
  width: 100%;
  min-height: $PLACEHOLDER_HEIGHT;
  max-height: 100%; //$CARD_HEIGHT;
  height: auto;
  margin-bottom: 10px;
  border-radius: $CARD_CORNERS;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  &.truncated {
    max-height: $PREVIEW_CARD_HEIGHT;
  }

  &.truncated.has-content {
    max-height: $PREVIEW_CARD_HEIGHT;

    .position {
      max-height: $PREVIEW_CARD_HEIGHT;
    }
  }

  &.has-content {
    .position {
      max-height: $CARD_HEIGHT;
    }
  }

  .position {
    background: #E3EDF7;
    box-shadow: inset 3px 3px 7px rgba(136, 165, 191, 0.48), inset -3px -3px 7px #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $CARD_CORNERS;
    width: 100%;
    height: 100%;
    flex: 1;
    margin-left: $CARD_CORNERS; // for box-shadow

    &.drop-zone {}

    &.drop-over {
      background: darken(#E3EDF7, 10%);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.card-container {
  border-radius: $CARD_CORNERS;
  box-shadow: 6px 2px $CARD_CORNERS rgba(136, 165, 191, 0.48), -6px -2px $CARD_CORNERS rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.preview {
    box-shadow: 6px 2px $CARD_CORNERS rgba(136, 165, 191, 0.48), -6px -2px $CARD_CORNERS rgba(136, 165, 191, 0.8);
  }

  .st-card {
    box-shadow: none;
  }

  &.change-height {
    height: $PREVIEW_PLACEHOLDER_HEIGHT;
    min-height: $PREVIEW_PLACEHOLDER_HEIGHT;
    max-height: unset;
  }

  &.is-dragging {
    box-shadow: none;

    .st-card, .st-top6 {
      opacity: 0;
    }
  }
}

.trash {
  opacity: 1;
  display: flex;
  justify-content: center;
  filter: none;
  background: none;

  span:hover,
  &:hover {
    filter: none;
    cursor: pointer !important;
  }

  &.isOver {
    color: $danger;
  }

  &:before {
    background: none;
  }

  .tile-container {
    align-items: center;
    background: none;
    background: darken(#E3EDF7, 6%);
  }
    .tile-image-inset,
    .tile-image-overlay {
      width: $tile-width;
      height: $tile-height;
      background-size: cover;
      background-position: center;
      position: absolute;
    }

    .tile-image-overlay {
      z-index: 101;
      position: absolute;
      opacity: 0;
      transition: .2s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .2s ease-out;
      &.active {
        opacity: 1;
      }
    }
}
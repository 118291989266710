$transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

.auth-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;

  input {
    line-height: 50px;
    width: 100%;
  }
}

.auth-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  position: absolute;
  left: 0;
  margin-left: 0;
  transition: $transition;

  &.in {
    left: 100%;


    .sign-up-pass-wrapper {
      margin-left: 24px;

      .has--error {
        &::after {
          bottom: -38px;
        }
      }
    }

  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.auth-content-title {
  max-width: 100%;
  text-align: center;
}

.auth-content-subtitle {
  max-width: 100%;
  text-align: center;
  color: $grey-dark;
  margin-bottom: 28px !important;
}

.auth-content-text {
  max-width: 100%;
  color: $text;
  // font-family:
  font-size: 1.125rem;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 16px;
}

.terms-wrapper {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8125rem;
  color: $text;
  margin-top: 16px;
}

#terms {
  display: none;
}

.terms-wrapper label {
  padding-left: 1.75rem;
  position: relative;
  border-radius: 1px;
  min-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::before {
    background-color: #fff;
    position: absolute;
    top: -1px;
    left: -1px;
    content: '';
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    box-sizing: border-box;
    outline: 0;
    border: 1px solid $primary;
  }
}

#terms:checked+label::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  font-size: 1.125rem;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
  outline: 0;
  border: none;
  background: url('../images/icon_checked.png') center center/16px 12px no-repeat #df0d50;
  padding: 1px 0 0 1px;
}

.terms-link {
  color: $primary;
  cursor: pointer;
  outline: 0;
}

.sign-up-pass-wrapper {
  position: relative;

}

.sign-up-pass-eye {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 1rem auto;
  right: 16px;
  width: 26px;
  height: 16px;
  cursor: pointer;
  background: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2226%22 height%3D%2217%22 viewBox%3D%220 0 26 17%22 style%3D%22opacity%3A .5%22%3E  %3Cpath fill%3D%22%23555a59%22 d%3D%22M25.85 8.55C21.77 3.41 17.38.88 12.8 1 5.42 1.22.34 8.28.13 8.58l-.03.05-.02.03-.03.09-.02.04-.02.08-.01.06v.14l.01.06.02.08.02.04.03.09.02.03.03.05c.21.3 5.29 7.36 12.67 7.58h.34c4.46 0 8.73-2.54 12.71-7.55.1-.13.15-.29.15-.45a.74.74 0 0 0-.15-.45zm-13 7.04C7.11 15.43 2.73 10.48 1.54 9c1.19-1.48 5.57-6.43 11.31-6.59 4-.11 7.89 2.11 11.59 6.59-3.7 4.48-7.58 6.71-11.59 6.59z%22%2F%3E%3Cpath fill%3D%22%23555a59%22 d%3D%22M13 5a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 7a3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3 3 3 0 0 1-3 3z%22%2F%3E%3C%2Fsvg%3E') center center/contain no-repeat;
  transition: background 0.3s;
}

.auth-image-container,
.container-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: 0 0;
}

.auth-image-container {
  max-width: 36.25rem;
}

.auth-image-container {
  background-image: linear-gradient(to right,
      #31456A,
      #2c3e5f,
      #273755,
      #22304a,
      #1d2940);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
  z-index: 20;
  overflow: hidden;
  transition: $transition;

  &.in {
    right: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 1700px) {
  .auth-image-container {
    max-width: 30rem;

    &.in {
      margin-right: -30rem;
    }
  }

  .auth-content {
    max-width: calc(100% - 30rem);

    &.in {
      margin-left: calc((100% - 30rem) * -1);

    }
  }
}

@media screen and (min-width: 641px) and (max-width: 1500px) {
  .auth-image-container {
    max-width: 26.875rem;

    &.in {
      margin-right: -26.875rem;
    }
  }

  .auth-content {
    max-width: calc(100% - 26.875rem);

    &.in {
      margin-left: calc((100% - 26.875rem) * -1);
    }
  }
}

.animate-text {
  position: relative;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.container-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.image-container-title {
  max-width: 100%;
  color: #fff;
  text-align: center;
}

@media screen and (max-height: 700px) {
  .image-container-title {
    font-size: 2.125rem;
    margin-bottom: 16px;
  }
}

.image-container-text {
  width: 100%;
  max-width: 350px;
  color: #fff;
  font-size: 1.375rem;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-bottom: 32px;
}

@media screen and (max-height: 700px) {
  .image-container-text {
    font-size: 1.125rem;
    margin-bottom: 24px;
  }
}

.image-container-btn {
  @include button-light();
}

a {
  text-decoration: none;
}

.image-container-login {
  right: 0;
}

.sign-up-pass-wrapper {
  position: relative;
}

@media screen and (max-width: 640px) {
  .form-fieldset-input.with--margin:nth-child(2n) {
    margin-left: 0 !important;
  }

  .sign-up-pass-wrapper {
    margin-left: 0 !important;


  }

  .auth-wrapper {
    flex-direction: column;
    height: auto;
    display: block;
  }

  .auth-content {
    position: relative;
    top: 0;
    left: 0;
    padding: 128px 16px 64px;

    &.in {
      left: 0;
    }
  }

  .auth-image-container {
    padding: 64px 8px;
    height: auto;
    position: static;

    &.in {
      right: 0;
    }
  }
}
.public-shelf-page {
  .home-content {
    margin-top: 0;
    max-width: unset;

  }

  .lhs-wrapper {
    margin-top: 74px;
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .shelf {
      cursor: default !important;
    }

    .last-published {
      margin-right: 200px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      width: 100%;

    }
  }

  .rhs-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    max-width: 600px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    background-image: linear-gradient(to right,
        #dd056a,
        #de0464,
        #df055d,
        #df0857,
        #df0d50);

    color: white;

    .title,
    .auth-content-subtitle,
    .terms-link {

      color: white;
    }

    .auth-wrapper {

      margin-top: 74px;
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;

    }

    .auth-content {
      max-width: 100%;
      padding: 10px;
      padding-top: 0;
      margin-left: 0;
      left: 0;
      position: relative;

      .form-fieldset-input.has--error::after {
        color: $white;
      }

      .terms-wrapper {
        color: $white;
      }

      .terms-link {
        text-decoration: underline;
      }

      .auth-content-subtitle {
        margin: 30px;
        margin-top: 0;
      }

      .form {
        width: auto;

        .sign-up-pass-eye {
          right: 26px;
        }

        .form-input-box {
          display: inline;
        }
      }

    }
  }
}
.shelf-container.public {
  position: relative;
  margin-bottom: 30px;

  &:first-child {
    padding-top: 123px !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .shelf-user {
    display: flex;
    position: absolute;
    left: calc(125px * 2.5);
    margin-top: -30px;
    height: 56px;
    align-items: center;

    .shelf-avatar {
      width: 56px;
      height: 56px;
      background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
      //box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48), -4px -2px 16px #FFFFFF;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;

      img {
        transition: src 5ms ease-in;
      }
    }

    .user-name {
      font-size: 16px;
      line-height: 56px;
      margin: 0;
      padding:0;
      margin-left: 10px;
      width: calc(125px * 1);
      max-width: calc(125px * 1);
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      text-align: start;
      color: $st-dark;
      height: auto;
      font-weight: normal;
    }
  }
}
.bucket-container {
  position: relative;
}

.tile-grid {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;

  &.explore {
    height: 100%;
    cursor: pointer !important;
    padding-top: 83px; //for search bar at top
    padding-right: 20px;

    .tile-grid-col:nth-child(3) {

      padding-bottom: 20px;

    }
  }

  &.bucket {
    // flex: 1;
    // height: 100%;
    //padding-bottom: 120px;
    overflow: hidden;
  }

  &.shelf {
    overflow: unset;

    margin-bottom: 4px;

    .tile-grid-col:nth-child(4) {
      margin-top: 42px !important;
      margin-left: -31px !important;
    }


    .tile-grid-col:nth-child(5) {
      margin-top: 89px !important;
      margin-left: -32px !important;
    }

    .tile-grid-col:nth-child(3) {
      margin-top: -4px !important;
      margin-left: -32px !important;

      .tile:nth-child(2) {
        // margin-left: -4px;
      }
    }
  }

  &.shelf,
  &.bucket {
    border-radius: 6px;

    height: auto;
    flex-grow: 0;
    flex-direction: row;
    justify-content: flex-start;
  }

  &:hover {
    cursor: grab !important;
  }

  .tile-grid-col {
    display: 'flex';
    flex-direction: 'column';
    // float: left;
    // overflow: hidden;

    .tile,
    .tile-placeholder {
      &:first-child {
        margin-top: 0;
        margin-left: 0;
      }
    }

    &:nth-child(1) {
      margin-top: calc(0.4 * #{$tile-height});
      margin-left: 0;
    }

    &:nth-child(2) {
      margin-top: 90.5px;
      margin-left: -31px;
    }

    &:nth-child(3) {
      margin-top: calc(0.2 * 115px);
      margin-left: -31px;
    }

    &:nth-child(4) {
      margin-top: calc(0.2 * 115px + 46px);
      margin-left: -35px;
    }

    &:nth-child(5) {
      margin-top: calc(1 * 115px);
      margin-left: -35px;
    }
  }
}
.tile-placeholder {
  filter: none !important;

  .inset {
    //box-shadow: inset 3px 3px 7px rgba(136, 165, 191, 0.48), inset -3px -3px 7px #FFFFFF;
  }

  &:before {
    content: none !important;
  }

  .tile-container {
    background: transparent;
    background: darken(#E3EDF7, 6%); //lighten($tile-color, 50%);
  }

  img {
    opacity: 0;
  }

  span,
  .tile-text,
  .shelf-pos-marker,
  .expand-marker {
    display: none;
  }
}
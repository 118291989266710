html,
body {
  background: $bg-color;
  min-height: 100%;
  font-size: 1em;
  margin: 0;
  color: $dark-grey;
  overflow: hidden;
}

img {
  width: 100%;
}

.image-container {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 20px;
  flex-shrink: 0;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: $grey-light;
}
.delete-top6-drop-zone {
    &.is-over {
        .delete-zone-overlay {
            z-index: 10;
            background-color: rgba($st-base, 0.96);
            position: absolute;
            height: calc(100% - 159.14px);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            border-top: 2px dashed rgba($st-shade, 1);

            .instructions {
                opacity: 1;
            }
        }
    }

    .delete-zone-overlay {
        .instructions {
            opacity: 0;
            font-size: 24px;
            line-height: 32px;
            width: 100%;
            height: 100%;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: $st-dark;
            max-width: 45%;
        }
    }
}

.page-logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 140px;
  height: 45px;
  cursor: pointer;
  z-index: 30;
  background: url('../images/logo_color.png') center center no-repeat;
  background-size: contain;
}

.page-logo-alt {
  background: url('../images/logo_white.png') center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 640px) {
  .page-logo-alt {
    background: url('../images/logo_color.png') center center no-repeat;
    background-size: contain;
  }
}
.hidden-input {
  height: 0px;
  width: 0px;
  opacity: 0;
}

.datepicker-wrapper {
  position: relative;
  width: 100%;

  .label {
    display: inline;
  }

  .datepicker-input {
    border-bottom: 1px solid #e4e0e9;
    background: #fff;
    font-size: 13px;
    outline: none;
  }

  .is-focused {
    border-bottom: 1px solid $primary;
  }

  .is-invalid {
    border-bottom: 1px solid red;
  }

  .datepicker-label {
    color: #979ba7;
    width: 64px;
    line-height: 28px;
    display: inline-block;
  }

  .datepicker-value {
    padding: 0 8px;
    margin-left: 8px;
    line-height: 28px;
    text-align: center;
    border: none;
    outline: none;
    color: #26282a;
    width: 100px;
    font-size: 12px;
    display: inline-block;
  }

  .datepicker-value.is-focused {
    background-color: rgba($primary, 0.16);
    border-radius: 2px;
    height: 28px;
  }

  .datepicker-value.is-invalid {
    background-color: rgba(241, 88, 88, 0.16);
    border-radius: 2px;
    height: 28px;
  }

  .is-focused .arrow-down {
    border-color: $primary transparent;
  }

  .arrow-down {
    border-color: #e0e4e9 transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    float: right;
    margin-top: 12px;
    margin-right: 8px;
    cursor: pointer;
  }

  // .datepicker-calendar:after {
 //   content: "";
 //   position: absolute;
 //   transform: rotate(45deg);
 //   width: 12px;
 //   height: 12px;
 //   top: -7px;
 //   left: 102px;
 //   background: #fff;
 //   z-index: -1;
 // }

 // .datepicker-calendar:before {
 //   content: "";
 //   position: absolute;
 //   transform: rotate(45deg);
 //   width: 12px;
 //   height: 12px;
 //   top: -7px;
 //   left: 102px;
 //   box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
 //   z-index: -1;
 // }

  .datepicker-calendar {
    position: absolute;
    background: #fff;
    border-radius: 3px;
    padding: 12px 16px 16px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    top: 38px;
    width: 100%;
    margin-bottom: 30px;
    z-index: 1000;
  }

  .datepicker-calendar.is-active {
    border: 1px solid $primary;
  }

  .datepicker-calendar.is-active:after {
    border-top: 1px solid $primary;
    border-left: 1px solid $primary;
  }

  .day-header-wrapper {
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .month-header-wrapper {
    font-size: 13px;
    height: 20px;
    border-bottom: 1px solid #e4e0e9;
    display: flex;
    justify-content: space-between;
  }

  .title-wrapper {
    display: inline-block;
    width: calc(100% - 40px);
    color: #26282a;
    font-weight: bold;
  }

  .year-title {
    margin-left: 4px;
  }

  .days-wrapper {
    margin-bottom: 2px;
  }

  .arrow {
    color: $primary;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    user-select: none;
    position: relative;
  }

  .arrows {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .day-header,
  .day {
    display: inline-block;
    line-height: 20px;
    width: 24px;
    height: 20px;
    font-size: 11px;
    text-align: center;
  }

  .day-header {
    color: #b9bdc5;
  }

  .day {
    cursor: pointer;
  }

  .day.disabled {
    color: #979ba7;
    font-size: regular;
  }

  .day.selected {
    background: $primary;
    border-radius: 3px;
    color: white;
    font-weight: bold;
  }

  .day:not(.selected):hover {
    background: #e4e0e9;
    border-radius: 3px;
  }

  .date-part {
    margin: 0 4px;
    text-align: center;
  }
}
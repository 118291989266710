body {
  // padding: 0 !important;
}

.home-page {
  display: flex;
  width: 100%;
  height: 100vh;

  .lhs-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;

    align-items: center;
    flex-direction: column;
    max-height: 100%; // max-height: calc(100% - 20px);

    .tile-grid.shelf {
      margin: 0;
      padding: 10px;
    }
  }

  .shelf-grid-wrapper {
    position: relative;
    // height: 300px;
    // width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    // margin-right: 100px;
    // margin-left: 50px;

  }

  .rhs-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
    max-height: 100%;
    align-self: flex-start;
    width: 100%;
    max-width: 100%;



    // new to center items
    align-items: center;

    .dummy-suggestion {
      width: $tile-width;
    }

    .suggestion-tile-container {
      position: absolute;
      z-index: 2;
    }

    .rhs-search-header-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      z-index: 2;
      width: 520px;
      height: 56px;
      background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
      box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48), -4px -2px 16px #FFFFFF;
      border-radius: 16px;
      margin-right: 20px;
      margin-top: 28px;
      opacity: 1;
      position: absolute;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -ms-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;

      // background: linear-gradient(70.72deg, #FFFFFF -30.86%, #ECF1F7 -10.72%, #CFDCEB 112.31%);
      // box-shadow: inset 4px 4px 6px rgba(18, 46, 101, 0.15), inset -4px -4px 7px rgba(255, 255, 255, 0.7);
      // border-radius: 31px;

      &.hide {
        opacity: 0;
      }

      .search-box {
        margin: 0 20px;
        background: transparent;
        box-shadow: inset 2px 2px 6px transparent, inset -1px -1px 3px transparent;
        border: none;
        overflow: hidden;
        border-radius: 12px;
        width: 100%;

        div {
          position: absolute;
          right: 20px;
          z-index: 200;
          top: 5px;
        }

        &:focus-within {
          background: linear-gradient(89.8deg, #ECF1F7 -437.3%, #BDCFE4 499.81%);
          box-shadow: inset 2px 2px 6px rgba(18, 46, 101, 0.2), inset -1px -1px 3px #FFFFFF;
          overflow: hidden;

          .icon {
            opacity: 1
          }
        }

        .icon {
          color: #31456A;
          opacity: 0.6
        }

        input {
          background: transparent;
          color: #31456A;
          border-radius: 26px;
          border: none;
          box-shadow: none;

          &:focus,
          &:hover {
            outline: 0;
            border-color: transparent;
            box-shadow: none;
            flex-shrink: 0;
          }

          &::placeholder {
            color: #31456A;
            opacity: 0.6;
          }
        }
      }

      .search-close,
      .search-switch {
        cursor: pointer;
        opacity: 0.6;
        display: flex;
        align-items: center;

        img {
          height: 36px;
          width: auto;
          margin: 0;
        }

        svg {
          color: #31456A;
          font-size: 24px;
          line-height: 24px;
        }

        &:hover {
          opacity: 1;
        }
      }

      .search-close {
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1;
        margin-right: 20px;
        width: 100%;
      }

      .search-switch {
        padding: 0;
        text-align: center;
        box-sizing: border-box;
        font-weight: 500;
        user-select: none;
        justify-content: flex-start;
        margin-right: 15px;

        &:first-child {
          margin-left: 15px;
        }
      }

      &.search-content {
        display: flex;
        margin-bottom: 25px;
      }

      span {
        text-align: center;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }

    .rhs-search-container {
      display: flex;
      overflow-y: auto;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      // -webkit-justify-content: flex-end;
      // justify-content: flex-end;
      position: relative;
      flex-direction: column;
      padding-top: 0px;
      overflow-x: hidden;

      &.TEMPLATES {
        .tile-grid {

          .tile-grid-col:nth-child(3) {
            .tile:last-child {
              margin-bottom: 20px;
            }
          }
        }
      }


      &.PEOPLE {
        padding-right: 20px;

        &.searching {
          padding-top: 55px;

          .suggestion {
            display: none;
          }
        }
      }




      .shelf-container {
        &:first-child {
          padding-top: 83px;
        }
      }

    }
  }
}

.home-content {
  max-width: $max-content-width;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.public-shelf-filter {
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    svg {
      line-height: 20px;
      margin-right: 10px;
      font-size: 15px;
    }

    span {
      line-height: 20px;
      font-size: 15px;
    }
  }
}

div {
  -webkit-user-select: none;
  /* Webkit */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10  */
  /* Currently not supported in Opera but will be soon */
  -o-user-select: none;
  user-select: none;
}
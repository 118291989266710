.modal-content {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  // overflow: hidden;


  // font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.2rem;
  min-width: 600px;
  width: 100%;
  // overflow-x: hidden;
}

.modal-actions {

  .btn {
    // background-color: $brand-secondary;
    border: none;
    color: $white;
  }

}

.modal-custom.is-fullscreen {
  .modal-header {
    width: 100%;
    height: 106px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba($dark-grey, 0.12);
    z-index: 1;
  }

  .modal-header-inner {
    max-width: 1240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .modal-header-menu {
    flex: 1;
    display: flex;
    justify-content: center;

    .menu-item {
      font-weight: normal;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: 45px;
      }

      &.is-active {
        color: $primary;
      }
    }
  }

  .modal-header-title {
    font-size: 28px;
    font-family: $family-sans-serif;
    font-weight: bold;
    max-width: 250px;
    color: $st-dark !important;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .modal-header-actions {
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: flex-end;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background-color: #FAFAFA;
  }

  .modal-content-inner {
    max-width: 1240px;
    padding-top: 66px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
}

.modal-custom.is-default {
  .modal-header {
    width: 100%;
    height: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    // box-shadow: 0 1px 5px 0 rgba($dark-grey, 0.12);
    // border-bottom: 1px solid $light-grey;
    z-index: 1;
  }

  .modal-header-inner {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 15px;
  }

  .modal-header-title {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    color: $st-dark;
    font-family: $st-family-sans-serif;

    span {
      font-weight: bold;
      //text-transform: uppercase;
      width: 80px;
      height: 58px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      position: relative;
    }
  }

  .modal-header-menu {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;

    .menu-item {
      font-weight: normal;
      text-transform: uppercase;
      width: 80px;
      height: 58px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 0.5ms ease;
      cursor: pointer;
      position: relative;

      &:not(:last-child) {
        margin-right: 35px;
      }

      &:hover {
        color: $primary;
      }

      &::after {
        background-color: transparent;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: ' ';
      }

      &.is-active {
        color: $primary;
        font-weight: bold;

        &::after {
          background-color: $primary;
        }
      }
    }
  }

  .modal-header-actions {
    display: flex;
    justify-content: flex-end;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    //overflow: visible;


    &.no-scroll {
      overflow: hidden;
    }

    // background-color: #FAFAFA;
  }

  .modal-content-inner {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    min-width: 600px;
    padding-top: 35px;
  }
}

.undo-button {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  cursor: pointer;
}

h3.modal-content-header {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
}
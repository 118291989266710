.nav-icons {
  margin-left: 50px;

  flex-direction: row;
  display: flex;


}

.nav-icon {
  width: $tile-nav-icon-width;
  height: $tile-nav-icon-height;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &.home {
    .inner {
      background-color: $primary;

      &:before {
        content: ' ';
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        clip-path: $tile-clippath;
        background-color: $white;
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
      }
    }

    span {
      z-index: 1;
      color: $primary;
      font-size: 10px;
    }
  }

  &.mode1 {
    span {
      font-size: 10px;
    }
  }

  &.mode2 {
    span {
      font-size: 10px;
    }
  }

  .inner {
    clip-path: $tile-clippath;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary;
  }

  span {
    color: $white;
    line-height: 10px;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

}
.tile {
  width: $tile-width;
  height: $tile-height;
  margin-top: 3px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // clip-path: $tile-clippath;
  // clip-path: polygon(28.8% 0%, 73.6% 0%, 103.2% 36.5%, 74.4% 101.75%, 23.2% 103.5%, -2.4% 61.75%);
  filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.19));
  //clip-path: polygon(28.8% -1.7%, 76.8% -1.7%, 103.2% 37.05%, 74.4% 102.6%, 23.2% 104.3%, -2.4% 62.05%);
  clip-path: polygon(28% -2.6%, 72.8% -1.7%, 102.4% 39.65%, 73.6% 101.7%, 24% 101.7%, -2.4% 62.05%);

  &:before {
    content: '';
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.19));
  }


  &.disable {
    opacity: 0.5;
  }

  &:hover {
    cursor: grab !important;
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.311));
  }

  &.dragging {
    filter: none;
  }

  &.over {
    //opacity: 0.7;
  }
}

.tile img {
  position: relative;

  &:hover {
    cursor: grab !important;
  }
}

.tile-inner-span {
  position: absolute !important;
  left: 0;
  top: 0;
}

.tile-inner {
  clip-path: $tile-clippath;
  -webkit-clip-path: $tile-clippath;
  shape-outside: polygon(0 0, 0 115px, 33.125px 115px, 33.1818182px 115px, 0 70.2588757px, 35.5113636px 0, 0 0);
  position: relative;
  width: $tile-width;
  height: $tile-height;
  overflow: hidden;
  // padding: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile-container {
  $hex-color: var(--hex-color, $tile-color);
  $rgb-color: var(--rgb-color, $tile-color);

  background-image: linear-gradient(-15deg,
      rgba($rgb-color, 0.8) 60%,
      rgba(#fff, 0.55) 97%);

  background-color: $hex-color;
  background-repeat: no-repeat;
  background-size: contain;
  clip-path: $tile-clippath;
  position: relative;
  width: $tile-width;
  height: $tile-height;
  display: flex;
  justify-content: center;

  svg {
    font-size: 1.5em;
  }
}

.tile:before {
  content: ' ';
  width: $tile-width;
  height: $tile-height;

  clip-path: $tile-clippath;

  display: block;
  position: absolute;
  top: 1px;
  left: 1px;

  &:hover {
    cursor: grab !important;
  }
}

.tile span {
  position: relative;
  text-align: center;
  overflow: hidden;

  &:hover {
    cursor: grab !important;
  }
}
.deck-container {
  overflow: hidden;
  width: 100%;
  // padding: 0 2rem;
}

.options-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
  position: relative;

  .ps__rail-x {
    display: flex;
  }
}

.options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // width: 100%;
  // height: 150px;
}
.image-gallery {
  width: 100%;
  margin: 30px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  // overflow: hidden;
  // overflow-y: auto;

  // max-height: 400px;

  &.hide {
    visibility: hidden;
  }
}

.image-gallery-outer {
  width: 100%;
}

.image-gallery-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.scrollarea-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.image-gallery-item {
  max-width: 25%;
  height: 100px;
  box-sizing: border-box;
  flex: 1 1 30%;
  margin-bottom: 1rem;

  flex-basis: 33%;

  &:hover {
    cursor: pointer;
  }

  figure {
    margin-left: .5rem;
    margin-right: .5rem;
    height: 100%;
    min-width: calc(100% - 1rem);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    border: 1px solid $grey100;
    background: #fafafa;

    img {
      display: block;
      max-width: 100%;
    }
  }
}
$suggestion-tile-width: $tile-width;
$suggestion-tile-height: $suggestion-tile-width * 0.922;

.suggestion {

  // position: absolute;
  // right: 0;



  &.tile {
    max-width: $suggestion-tile-width;
    width: $suggestion-tile-width;
    height: $suggestion-tile-height;
    align-self: flex-end;
    right: 20px;
    margin-top: calc(84px + 81px);
    position: absolute;
    z-index: 2;

    &.preview {
      width: $tile-width;
      height: $tile-height;

      &:before {
        width: $tile-width;
        height: $tile-height;
      }

      .tile-container {
        width: $tile-width;
        height: $tile-height;

      }
    }
  }

  .tile-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: $suggestion-tile-width;
    height: $suggestion-tile-height;


    color: $white;

    svg: {
      font-size: 28px;
    }

    .tile-text {
      font-size: 14px;
      margin-top: 5px;
      height: auto;
    }

    .tile:before {
      width: $suggestion-tile-width;
      height: $suggestion-tile-height;
    }
  }
}
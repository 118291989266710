.marker {
  width: calc(#{$tile-micro-width} * 1.2);
  height: calc(#{$tile-micro-height} * 1.2);

  position: absolute;

  display: block;
  margin: auto;
  z-index: 2;

  span {
    font-size: 10px;
    line-height: 15px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .inner {
    clip-path: $tile-clippath;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-color: #e7eff9;
    background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
  }
}

.expand-marker {
  top: 24px;
  right: 14px;
}

.shelf-pos-marker {
  top: 40px;
  right: 5px;
  // width: $tile-micro-width;
  // height: $tile-micro-height;
  // right: 8px;
  // position: absolute;
  // top: 40px;
  // display: block;
  // margin: auto;
  // z-index: 2;
  // width: 15px;
  // height: 15px;
  // // border-radius: 50%;
  // // background: #FFF;
  // // background: -webkit-radial-gradient(5px 5px, circle, #FFF, #333);

  // span {
  //   font-size: 10px;
  //   line-height: 15px;
  //   font-weight: 700;
  //   height: 100%;
  //   justify-content: center;
  //   display: flex;
  //   align-items: center;
  // }

  // .inner {
  //   clip-path: $tile-clippath;
  //   position: relative;
  //   display: flex;
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: white;
  //   background-color: #e7eff9;
  //   background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
  // }
}
.mouse-over-tooltip-popover {


  .mouse-over-tooltip-top {

    top: 0px !important;
  }

  .mouse-over-tooltip-bottom {
    // margin-top: -10px !important;
  }

}

.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: black !important;
  padding: 4px 8px;
  border-radius: 2px;

  .tooltip-text {
    text-align: center;
    font-family: $family-condensed;
    font-size: 12px;

    &.noun {
      font-weight: 600;
      color: white
    }

    &.text {

      color: white;
    }
  }
}

.mouse-over-tooltip {
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
}
.bucket-scroll-area-tiles {

  position: absolute;
  display: flex;
  justify-content: flex-start;

  flex-direction: row;
  margin-top: -50px;

  .UP {
    margin-top: 52px;
    display: none;

    .tile-grid-col {
      &:first-child {
        margin-top: 24px !important;
        margin-left: 0px !important;
      }

      &:nth-child(2) {
        margin-top: 71px !important;
        margin-left: -32px !important;
      }

      &:nth-child(3) {
        margin-top: 0px !important;
        margin-left: -28px !important;

        .tile:first-child {
          visibility: hidden;
        }

        .tile:nth-child(2) {
          margin-left: 0;
        }
      }

      &:nth-child(4) {
        margin-top: 41px !important;
        margin-left: -34px !important;
      }

      &:nth-child(5) {
        margin-top: 86px !important;
        margin-left: -32px !important;
      }
    }
  }



  &.DOWN {
    margin-bottom: -93px;
    right: 0px;
    bottom: 0;
    // margin-top: -93px;
    display: none;

    .tile-grid-col {


      &:last-child {
        display: none;
      }
    }
  }



  .tile {
    clip-path: $tile-clippath; //polygon(22.4% 60.85%, 60.8% 58.25%, 90.4% 59.15%, 72% 100%, 27.2% 100%, 0% 61.75%);

    &:first-child {
      margin-top: -44px;
    }

    &:nth-child(2) {
      margin-top: 0px;
      margin-left: -31px;
    }

    &:nth-child(3) {
      margin-top: 0px;
      margin-left: -31px;
    }


    &:nth-child(4) {
      margin-top: 0px;
      margin-left: -31px;
    }

    .tile-container {
      background: transparent;
    }
  }
}

.bucket-scroll-area {

  position: absolute;

  z-index: 20;
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  padding: 80px;


  &.UP {
    top: 0;
    clip-path: polygon(32px 67px, -4px 23px, 23px 15px, 73px 16px, 111px 31px, 122px 38px, 180px 48px, 218px 81px, 273px 81px, 299px 54px, 319px 22px, 368px 20px, 412px 53px, 461px 62px, 486px 76px, 466px 127px, 407px 128px, 373px 81px, 317px 82px, 278px 152px, 218px 150px, 187px 113px, 125px 114px, 92px 66px);

  }

  &.DOWN {
    bottom: 0;
    clip-path: polygon(402px 118px, 481px 109px, 484px 225px, 379px 236px, 329px 236px, 297px 240px, 281px 239px, 226px 241px, 187px 242px, 129px 242px, 107px 269px, 87px 277px, 30px 262px, 28px 259px, 22px 246px, 24px 229px, 30px 214px, 28px 189px, 29px 185px, 30px 175px, 86px 173px, 124px 102px, 183px 104px, 215px 140px, 276px 138px, 316px 68px, 373px 61px, 387px 83px);
  }
}
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=PT+Sans:300,400,500,600,700,900|Roboto+Condensed:400,700');
@import '~shared-code/lib/assets/styles/variables';
// Set brand colors
$dark-grey: #333333;
$white: #ffffff;
$red: #FC3C63;
$green: #4EC677;
$success: $green;
$danger: $red;
$light: #F5F5F5;
$light-grey: #eef5f3;
$black: #202121;
$tile-color: #7888a3;
$tile-ratio: 0.922;
$tile-width: 125px; //large: 125px , small: 110px
$tile-height: $tile-width * $tile-ratio;
$grey100: #FAFAFA; // backgrounds
$grey200: #E5E5E5; // list hover and backgrounds
$grey300: #CCCCCC; // separators and disabled icons
$grey400: #999999; // text
$grey500: #666666; // body text
$grey600: #333333; // headings
$blue: #2D4EF5;
$info-color: $blue;
$bg-color: #E3EDF7; // linear-gradient(318.32deg, #C3D1E4 -0.02%, #DDE7F3 54.14%, #D4E0ED 100.43%); // #E7F0F9; // ;
$pressed-gradient: linear-gradient(308.75deg, #DEE9F3 -5.36%, #5F83AD 83.64%);
;

$tile-micro-width: 18px;
$tile-micro-height: $tile-micro-width * 0.922;
$tile-nav-icon-width: 40px;
$tile-nav-icon-height: $tile-nav-icon-width * 0.922;
$tile-image-cropper-height: 325px;
$tile-image-cropper-width: $tile-image-cropper-height / $tile-ratio;

$tile-clippath: polygon(28.364% 0,
  73.455% 0,
  100% 38.955%,
  71.636% 100%,
  26.545% 100%,
  0 61.095%);

$tile-text-shape-outside-left: polygon(53.1346613% 100%, 0 100%, 0 0px, 53.1346613% 100%);
$tile-text-shape-outside-right: polygon(42.7419354839% 100%, 80.8101290323% 0, 80.8101290323% 100%, 2.7419354839% 100%);


$max-content-width: 1750px;
$lightest-grey: #e0e0e0; // #e0e0e0 - divider
$lighter-grey: #bdbdbd; // #bdbdbd - hint text

// Update Bulma's global variables
$family-sans-serif: 'Questrial',
sans-serif;
$family-primary: $family-sans-serif;
$grey-dark: $dark-grey;
$grey-light: $light-grey;
$primary: #31456A;
$text: $black;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-background-color: $grey-light;
$input-border-color: transparent;
$input-shadow: none;

// Generic variables
$body-family: $family-primary;
$family-condensed: 'Roboto Condensed',
sans-serif;

// SOCIALS
$twitter: #38A1F3;
$facebook: #3b5998;
$dark-alt: #7989A2;
$light-alt: darken(#E3EDF7, 6%);
$brand: #2196f3;
$errorColor: #f44336;
$timing: .15s;

.tile-placeholder {
  .action-menu {
    display: none;
  }
}

.action-menu {
  position: absolute;
  top: 2px;
  right: 30px;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 20;
  line-height: 1;
  width: calc(#{$tile-micro-width} * 1.2);
  height: calc(#{$tile-micro-height} * 1.2);

  .menu-item {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 6px;
    top: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    color: #222;
    transform: translate3d(-100%, 100%, 0) scale(0);
    transform-origin: 4em -1em;
    transition: transform $timing cubic-bezier(0.8, -0.65, 0.2, 1.65), background $timing ease-out, border-radius $timing/1.5 ease-in-out;

    .inner {
      clip-path: $tile-clippath;
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;
      transition: background $timing ease-out;
      background-color: #e7eff9;
      background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
    }
  }

  .journeys {
    .inner {
      background-color: #e7eff9;
      background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
      color: #222;
    }
  }

  .edit {

    .inner {
      background-color: #e7eff9;
      background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
      color: #222;
    }

    transition-delay: $timing/2;
  }

  .toggler {
    transform: translate3d(0, 0, 0) scale(1) rotate(0deg);
    z-index: 1;

    &:hover {
      .inner {
        background: $primary;
        color: $light
      }
    }
  }

  &.active {
    .menu-item:nth-child(2) {
      transform: translate3d(58%, 95%, 0) scale(1);
    }

    .menu-item:nth-child(3) {
      transform: translate3d(115%, 190%, 0) scale(1);
    }

    .toggler {

      .inner {
        background: $primary;
        color: $white;
      }
    }
  }
}
h1,
h2,
h3,
h4,
h5,
button,
input,
select,
textarea {
  font-family: $family-primary;
  font-weight: bold;
}
